import React, { useEffect } from "react";
import BecomeRecognised from "../../component/BecomeRecognised";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateReferralClicks,
  updateReferralShares,
} from "../../feature/home/homeSlice";
import queryString from "query-string";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const { s, ref, t } = queryParams;

    if (s && ref && t) {
      if (t === "c") {
        dispatch(updateReferralClicks({ ref_code: ref }));
      } else if (t === "s") {
        dispatch(updateReferralShares({ ref_code: ref }));
      }

      // Remove the query parameters from the URL
      const updatedQueryParams = { ...queryParams };
      delete updatedQueryParams.s;
      delete updatedQueryParams.ref;
      delete updatedQueryParams.t;
      const newQueryString = queryString.stringify(updatedQueryParams);
      const newUrl = `${window.location.pathname}${newQueryString ? `?${newQueryString}` : ""
        }`;
      navigate(newUrl, { replace: true });
    }
  }, [dispatch, navigate]);

  return (
    <div className="bg-[#F9FAFB]">
      <div className="max-w-[1366px] m-auto px-[20px] pb-16 max-md:px-0">
        <div
          className="flex items-center justify-center min-h-screen"
          style={{
            position: "relative",
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          <div className="flex flex-col mx-auto mt-9 max-w-[811px] max-sm:mt-[50px]  ">
            <div className="w-full text-6xl font-bold tracking-tighter text-center text-blue-900 leading-[67.2px] max-md:max-w-full max-md:text-4xl max-sm:px-3 max-sm:mt-6 max-sm:text-4xl">
              You Are Who You Say You Are
            </div>
            <div className="w-full mt-4 text-3xl leading-9 text-center text-neutral-600 max-md:px-5 max-md:max-w-full max-md:text-2xl max-sm:px-4 max-sm:text-lg">
              Bringing Credibility, Integrity, and Protection to Industry Professionals, Individuals and Clients Worldwide
            </div>
            <div className="flex self-center justify-between gap-5 px-5 mt-8 text-xl">
              <div
                className="justify-center px-9 py-4 font-bold text-white bg-green-700 rounded-[70px] max-md:px-5 max-md:text-base max-sm:text-sm cursor-pointer"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Get Verified
              </div>
              <div
                className="my-auto font-semibold text-blue-900 cursor-pointer max-md:text-base max-sm:text-sm"
                onClick={() => {
                  navigate("/benefits");
                }}
              >
                Learn More
              </div>
            </div>
          </div>
          <div
          // style={{
          //   width: "100%",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   background: "red",
          //   position: "relative",
          // }}
          >
            <img
              loading="lazy"
              alt="banner"
              src="/hero-images.png"
              className="object-contain w-full mt-12 aspect-[2.86] max-md:max-w-full max-md:mt-14 z-[1]"
            // style={{
            //   overflow: "hidden",
            //   position: "absolute",
            //   zIndex: "-1",
            //   top: "50%",
            //   left: "50%",
            //   transform: "translate(-45%, -20%)",
            // }}
            />
          </div>

          <section className="self-end mt-12 w-[1177px] max-w-full mx-auto max max-md:mt-0 max-md:px-3">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-3/5 max-md:ml-0 max-md:w-full">
                <div className="self-stretch my-auto max-md:mt-5 max-md:max-w-full">
                  <article className="flex items-center gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e956f7c2148679fb34ae410b72031731a5fed55770f8e1d6a27431e4d5a6767?apiKey=3ae96302e69649f5be368f89230b0596&"
                        alt="Verification icon"
                        className="grow shrink-0 self-stretch my-auto max-w-full aspect-[1.59] max-sm:w-[140px] max-lg:w-[140px] max-lg:ml-[5px] w-[250px] max-md:mt-7"
                      />
                    </div>
                    <div className="flex flex-col ml-5 w-[60%] max-md:ml-5  max-md:w-full ">
                      <h2 className="self-stretch text-3xl font-bold leading-10 tracking-tighter text-blue-900 max-md:mt-4">
                        Promote Confidence and Ensure Trust Through Verification
                      </h2>
                    </div>
                  </article>
                </div>
              </div>
              <div className="flex flex-col w-2/5 ml-3 max-md:w-full">
                <div className="flex flex-col self-stretch justify-center grow text-neutral-600 max-md:mt-9">
                  <p className="text-lg font-medium leading-6 tracking-normal ">
                    In today's constantly changing digital landscape, differentiating yourself from others, building confidence in your abilities, and demonstrating your talents in compelling ways has become more important than ever.
                  </p>
                  <p className="mt-4 text-base leading-5 tracking-normal">
                    We are excited to introduce you to FullIDCheck, a secure and verified platform that empowers anyone to establish trust within their community and beyond. Whether you're a professional seeking to demonstrate your expertise or an individual aiming to verify your identity, FullIDCheck gives you the platform you deserve to shine and build lasting, trustworthy relationships.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="flex flex-col p-8 mt-12 w-full bg-stone-50 max-w-[1307px] rounded-[30px] max-md:px-6 max-md:mt-10 max-md:max-w-full">
            <div className="max-md:max-w-full">
              <div className="flex gap-1 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col w-full p-8 bg-white grow rounded-3xl max-md:px-5 max-md:mt-4 max-md:max-w-full pb-40 max-md:pb-10">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/847a712d1df93557d257d4b9573a43ffa4d815402dff72094b6152e85552d7a4?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="max-w-full aspect-[1.72] w-[110px]"
                    />
                    <div className="mt-2 text-3xl font-semibold leading-8 tracking-tighter text-neutral-800 max-md:max-w-full">
                      Reputation Boost
                    </div>
                    <div className="mt-4 text-base leading-7 tracking-tight text-neutral-600 max-md:max-w-full">
                      "Being verified on FullIDCheck boosts your reputation. Our thorough process ensures people can trust your qualifications and experience, giving you credibility that stands out."
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-6/12 ml-3 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col w-full p-8 bg-white grow rounded-3xl max-md:px-5 max-md:mt-4 max-md:max-w-full pb-40 max-md:pb-10">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/43a3a6b66def634b452e41ae32493145a2d882369a7a283b799aaad819c7bef1?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="aspect-[0.91] w-[58px]"
                    />
                    <div className="mt-2 text-3xl font-semibold leading-8 tracking-tighter text-neutral-800 max-md:max-w-full">
                      Increased Trust
                    </div>
                    <div className="mt-4 text-base leading-7 tracking-tight text-neutral-600 max-md:max-w-full">
                      "Trust matters. By joining our verified database, you show clients you're reliable and credible, giving you a major edge in securing more opportunities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 max-md:max-w-full">
              <div className="flex gap-1 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col w-full p-8 bg-white grow rounded-3xl max-md:px-5 max-md:mt-0 max-md:max-w-full pb-40 max-md:pb-10">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b5a57e6e9dd18fd9d5e97cb3a976081ef203a2a890cbe0190fbfe133ce766dc?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="max-w-full aspect-[1.61] w-[104px]"
                    />
                    <div className="mt-2 text-3xl font-semibold leading-8 tracking-tighter text-neutral-800 max-md:max-w-full">
                      Unlimited Opportunities
                    </div>
                    <div className="mt-4 text-base leading-7 tracking-tight text-neutral-600 max-md:max-w-full">
                      "Get verified and open the door to new projects and collaborations. FullIDCheck connects you with clients who value trust, helping you advance your career."
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-6/12 ml-3 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col w-full p-8 bg-white grow rounded-3xl max-md:px-5 max-md:mt-4 max-md:max-w-full pb-40 max-md:pb-10">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bcfb85e6b1346723a21e1a7f4f39b7bd6ddcb4e005654081a5a6cffed3c9a181?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="aspect-[1.49] w-[95px]"
                    />
                    <div className="mt-2 text-3xl font-semibold leading-8 tracking-tighter text-neutral-800 max-md:max-w-full">
                      Community Building
                    </div>
                    <div className="mt-4 text-base leading-7 tracking-tight text-neutral-600 max-md:max-w-full">
                      Join a community of professionals where you can share ideas, collaborate, and grow your career through trusted relationships
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-3.5 py-1.5 mt-12 w-full text-lg tracking-tight text-center max-w-[1131px] max-md:mt-10 max-md:max-w-full max-md:px-4">
            <div className="leading-7 text-neutral-600 max-md:max-w-full">
              "Register to expand your client base globally and build trust with potential clients through verified credentials."
            </div>
            <div className="self-center mt-20 text-blue-900 leading-[162%] max-md:mt-10 max-md:max-w-full">
              <span className="font-bold text-blue-900">By registering</span>
              <span className="text-blue-900">,</span>{" "}
              <span className="text-neutral-600">you assure people that </span>
              <span className="text-xl font-bold text-blue-900">
                “YOU ARE SECURE, YOU ARE VERIFIED, YOU ARE TRUSTWORTHY.”
              </span>
            </div>
          </div>

          {/* Insert here */}
          <BecomeRecognised btnText={"Get Verified"} btnColor={false} />
          
          <div className="self-start mt-12 text-3xl font-bold leading-10 tracking-tighter text-blue-900 max-md:mt-12 max-md:max-w-full max-sm:self-center max-sm:text-left">
            Register in 3 Easy Steps
          </div>

          <div className="justify-center self-end mt-9 max-w-full w-full max-md:w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-10 w-full text-2xl font-semibold tracking-tight leading-6 text-white bg-blue-900 rounded-3xl border-blue-900 border-solid border-t-[12px] max-md:px-6 max-md:mt-4 max-md:pb-14 max-md:rounded-none">
                  <div className="flex justify-center items-center px-2.5 text-center whitespace-nowrap bg-teal-400 h-[60px] rounded-[100px] w-[60px] max-md:px-4 max-md:w-10 max-md:h-10">
                    1
                  </div>
                  <div className="mt-6 max-md:text-xl">Register</div>
                  <div className="mt-4 text-lg font-normal leading-7 tracking-tight max-md:text-sm">
                    Create your account and start the verification process.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-10 w-full text-2xl font-semibold tracking-tight leading-6 text-white bg-blue-900 rounded-3xl border-blue-900 border-solid border-t-[12px] max-md:px-6 max-md:mt-4 max-md:pb-14 max-md:rounded-none">
                  <div className="flex justify-center items-center px-2.5 text-center whitespace-nowrap bg-teal-400 h-[60px] rounded-[100px] w-[60px] max-md:px-4 max-md:w-10 max-md:h-10">
                    2
                  </div>
                  <div className="mt-6 max-md:text-xl">Authenticate</div>
                  <div className="mt-4 text-lg leading-7 font-normal tracking-tight max-md:text-sm">
                    Use facial recognition and upload your passport, driver's license, or country ID
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-10 w-full text-2xl font-semibold tracking-tight leading-6 text-white bg-blue-900 rounded-3xl border-blue-900 border-solid border-t-[12px] max-md:px-6 max-md:mt-4 max-md:pb-14 max-md:rounded-none">
                  <div className="flex justify-center items-center px-2.5 text-center whitespace-nowrap bg-teal-400 h-[60px] rounded-[100px] w-[60px] max-md:px-4 max-md:w-10 max-md:h-10">
                    3
                  </div>
                  <div className="mt-6 max-md:text-xl">Get Verified</div>
                  <div className="mt-4 text-lg leading-7 font-normal tracking-tight max-md:text-sm">
                    Receive your FullIDCheck badge number to display in emails, websites, start building your profile.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start mt-16 w-full max-w-[1307px] max-md:mt-4 max-md:max-w-full">
            <div className="max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[39%] max-md:ml-0 max-md:w-full max-lg:h-full max-lg:mt-[50px]">
                  <img
                    alt="img"
                    loading="lazy"
                    src="./professions.png"
                    className="grow w-full aspect-[1.75] max-md:mt-0 max-md:max-w-full"
                  />
                </div>
                <div className="flex flex-col ml-5 w-[61%] max-md:ml-0 max-md:w-full max-md:px-6">
                  <div className="flex flex-col self-stretch grow max-md:mt-8 max-md:max-w-full">
                    <div className="text-3xl font-bold leading-10 tracking-tighter text-blue-900 max-md:max-w-full">
                      Unlocking Opportunities and Trust with FullIDCheck
                    </div>
                    <div className="text-base leading-7 tracking-tight mt-9 text-neutral-600 max-md:max-w-full">
                      FullIDCheck is open to everyone, offering a secure and verified platform that promotes trust across all types of professional and personal networks. Whether you're a professional looking to showcase your expertise or simply want to verify your identity for peace of mind, FullIDCheck is here for you.
                      <br />
                      <br />
                      By registering, you can gain the advantage of expanding your reach to a global audience. Our verification process substantiates your identity and, for professionals, your skills and experience, helping to build trust with potential clients, employers, or anyone you interact with. FullIDCheck sets you apart as a credible, verified individual, opening doors to stronger relationships and greater opportunities in your personal and professional life.field.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

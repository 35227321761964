import React from "react";
import TopBanner from "../../component/TopBanner";
import { useNavigate } from "react-router-dom";

function Contact() {
  const bannerImg =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b97ef60e60deaf8141f2e03553440b4cd62ea1dc6f11a1fe2e6302e2fd4f9ca6?apiKey=3ae96302e69649f5be368f89230b0596&";
  const navigate = useNavigate();
  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/contact-banner.png"} />
      <div className="max-w-[1366px] m-auto p-[20px] max-md:p-0 max-md:pb-16">
        {/* Section-1 */}
        <div className=" w-full m-auto max-md:rounded-none justify-between px-12 py-8 mt-10 rounded-2xl max-w-[1360px] max-md:px-6 max-md:mt-16 max-md:max-w-full bg-gradient-to-br from-[#28b9bb] to-[#1c3775]">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
              <div className="text-3xl font-semibold tracking-tight leading-9 text-white max-md:max-w-full">
                Are you interested in a management position?
              </div>
              <span className="text-sm leading-6 text-white">
                Situations vacant for country managers, check and see if your
                country that you live in is available
              </span>
            </div>
            <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
              <div
                onClick={() => navigate("/apply-country-manager")}
                className="max-lg:text-[18px] cursor-pointer max-lg:px-0 justify-center self-stretch px-9 py-3 my-auto w-full text-xl font-semibold text-blue-900 bg-zinc-100 rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center "
              >
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* Section-2 */}
        <div className="w-full m-auto pb-16 max-md:p-[20px]">
          <div className="mt-14 text-2xl font-bold tracking-tight leading-8 text-blue-900 max-md:mt-10 max-md:max-w-full max-sm:text-center">
            Blockchain Management Corporation
          </div>
          <div className="mt-6 max-w-full w-full max-sm:mt-0 max-md:px-1">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow items-start text-neutral-600 max-md:mt-9 max-md:max-w-full">
                  <img
                    alt="img"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cf25ab20d2da5168b70f6806b4761da0bcffe9771f72ddba343ef41d33c8213?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="self-stretch w-full aspect-[2.04] max-md:max-w-full"
                  />
                  <div className="mt-6 text-lg font-semibold tracking-tight leading-5">
                    Office
                  </div>
                  <div className="mt-2 text-sm tracking-tight leading-6 w-[352px]">
                    Ground Floor, 470 Street, Kilda Road
                    <br />
                    Melbourne VICTORIA 3004 Australia
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full p-8 bg-white border border-[#E5E7EB] rounded-2xl max-md:mt-6 max-md:px-2">
                <div className="flex flex-col grow self-stretch py-px w-full rounded-3xl max-md:px-5 max-md:mt-9 max-md:max-w-full">
                  <input
                    className="justify-center items-start p-2.5 text-base leading-5 rounded-xl border border-solid
                   border-neutral-400 text-black max-md:pr-5"
                    placeholder="Full Name"
                  />

                  <input
                    className="justify-center items-start p-2.5 mt-6 text-base
                   leading-5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 text-black max-md:pr-5"
                    placeholder="Email"
                  />
                  <textarea
                    className="justify-center items-start px-2.5 pt-2.5 pb-12 mt-6 text-base leading-5 h-[160px] rounded-xl border border-solid border-neutral-400 text-black max-md:pr-5 max-md:pb-10"
                    placeholder="Say something..."
                  />

                  <div className="flex gap-3 justify-between mt-6 max-md:flex-col">
                    <div className="flex px-4 py-1.5 max-md:w-64 rounded-lg border border-solid shadow-sm bg-neutral-50 border-zinc-300">
                      <div className="flex gap-2 my-auto text-base items-center tracking-tight  text-neutral-400">
                        <input
                          type="checkbox"
                          // checked={checkboxChecked}
                          className="shrink-0 mt-[2px] w-4 h-4  rounded-md bg-zinc-300"
                          // onChange={() => {
                          //   setCheckboxChecked(!checkboxChecked);
                          // }}
                        />
                        <div className="text-sm">I'm not a robot</div>
                      </div>
                      <div className="flex flex-col justify-center px-1 text-xs font-medium text-center text-neutral-400">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c9ed15b993dfb9bdb270ab16bef6d2f2207dbf32c4789d78d6b7cc2fcc927f3?apiKey=3ae96302e69649f5be368f89230b0596&"
                          className="self-center aspect-[1.2] w-[41px]"
                        />
                        <div className="text-[10px]">Privacy-Terms</div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        alert("sent");
                      }}
                      className="cursor-pointer flex justify-center items-center max-md:w-full self-start px-20 py-2.5 text-base font-semibold text-white whitespace-nowrap bg-[#1C3775] rounded-full my-auto"
                    >
                      Send
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import React, { useState } from "react";
import PassUpdateSuccess from "./PassUpdateSuccess";
import { CiCircleCheck } from "react-icons/ci";
import BacktoLogin from "../components/BackToLogin";
import {
  forgetPasswordResetPassword,
} from "../../../api/apiCall.js";
import { IoKeyOutline, IoEyeSharp, IoEyeOff } from "react-icons/io5";

function UpdatePassword(props) {
  const { email, token } = props;
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      // eslint-disable-next-line no-useless-escape
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const handleUpdatePassword = async () => {
    if (!passwordsMatch) return;

    try {
      setLoading(true);
      let data = {
        payloads: {
          email: email,
          token: token,
          password: password,
          password_confirmation: confirmPassword,
        }
      }
      console.log("DATA: ", data)
      const response = await forgetPasswordResetPassword(data);
      if (response.status === 201) {
        setUpdateSuccess(true);
      }
    }
    catch (error) {
      console.log("Error updating your password: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {!updateSuccess && (
        <div className="flex flex-col items-center py-20 px-20 text-base bg-[#0082BA] flex-grow max-md:px-5">
          <div className="flex flex-col p-6 mt-6 max-w-full font-semibold bg-white rounded-2xl shadow-sm w-[400px] max-md:w-full max-md:px-5">
            <div className="flex items-center justify-between w-full mb-4">
              <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                <BacktoLogin />
              </div>
              <img src="/logoFooter.png" alt="Devreg Logo" className="h-8" />
            </div>
            <hr className="w-full border-t border-gray-200" />
            <div className="mt-8 mr-auto text-xl font-semibold text-center text-neutral-800 max-sm:mr-auto">
              Reset Password
            </div>
            <div className="mt-2 text-base text-zinc-700 text-opacity-80">
              Enter your new password
            </div>
            <div className="relative mt-4">
              <IoKeyOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                placeholder="New Password"
                value={password}
                type={showPassword ? "text" : "password"} // Toggle input type
                name="password"
                onChange={handleChange}
                className="w-full flex pl-10 flex-col justify-center px-5 py-2.5 text-base leading-5 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-neutral-600"
              />
              <div
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
                onClick={() => setShowPassword(!showPassword)} // Toggle visibility
              >
                {showPassword ? <IoEyeOff /> : <IoEyeSharp />}
              </div>
            </div>
            <div className="relative mt-4">
              <IoKeyOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={handleChange}
                disabled={
                  !isLengthValid ||
                  !hasUpperCase ||
                  !hasLowerCase ||
                  !hasNumber ||
                  !hasSpecialChar
                }
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="w-full flex pl-10 flex-col justify-center px-5 py-2.5 text-base leading-5 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-neutral-600"
              />
              <div
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <IoEyeOff /> : <IoEyeSharp />}
              </div>
            </div>
            {!passwordsMatch && (
              <div className="text-red-600 text-sm mt-1">
                Password doesn't match
              </div>
            )}

            <div className="mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
              Your password should:
            </div>
            <div
              className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? " text-green-700" : "  text-zinc-600"
                } `}
            >
              {isLengthValid ? (
                <CiCircleCheck
                  size={21}
                  className="min-w-[20px] max-w-[20px]"
                />
              ) : (
                <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
              )}
              <div className="flex-auto">
                Be at least 8 characters in length
              </div>
            </div>
            <div
              className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase
                ? " text-green-700"
                : "  text-zinc-600"
                } `}
            >
              {hasUpperCase && hasLowerCase ? (
                <CiCircleCheck
                  size={21}
                  className="min-w-[20px] max-w-[20px]"
                />
              ) : (
                <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
              )}
              <div className="flex-auto ">
                Contain both upper and lowercase alphabetic characters (e.g.
                A-Z, a-z)
              </div>
            </div>
            <div
              className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? " text-green-700" : "  text-zinc-600"
                } `}
            >
              {hasNumber ? (
                <CiCircleCheck
                  size={21}
                  className="min-w-[20px] max-w-[20px]"
                />
              ) : (
                <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
              )}
              <div className="flex-auto max-md:max-w-full">
                Have at least one numerical character (e.g. 0-9)
              </div>
            </div>
            <div
              className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasSpecialChar ? " text-green-700" : "  text-zinc-600"
                } `}
            >
              {hasSpecialChar ? (
                <CiCircleCheck
                  size={21}
                  className="min-w-[20px] max-w-[20px]"
                />
              ) : (
                <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
              )}
              <div className="flex-auto ">
                Have at least one special character (e.g. ~!@#$%^&*()_-+=)
              </div>
            </div>
            <button
              className={`justify-center  text-center cursor-pointer items-center px-9 py-3 mt-8 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 max-md:mt-6 ${
                loading ? "bg-gray-400" : "bg-blue-900"
              }`}
              onClick={() => handleUpdatePassword()}
              disabled={loading}
            >
              {loading ? (
                <svg className="animate-spin h-6 w-6 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" ></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" ></path>
                </svg>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      )}
      {updateSuccess && <PassUpdateSuccess />}
    </>
  );
}

export default UpdatePassword;

import React, { useState, useEffect , useRef} from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../../actions/index.js";
import {
  getProfile,
  updateProfile,
  searchProfession
} from "../../../../api/apiCall.js";
import { FaXmark } from "react-icons/fa6";
import debounce from "lodash.debounce";

function Overview() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [profileInfo, setProfileInfo] = useState({});
  const [profileCoverPhoto, setProfileCoverPhoto] = useState(null);
  const [profileCoverPhotoPreview, setProfileCoverPhotoPreview] = useState(user.profile_banner_path);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(user.profile_photo_path);
  const [aka, setAka] = useState(user.aka);
  const [about, setAbout] = useState(user.about);
  const [experience, setExperience] = useState(user.year_of_experience);
  const [projectsCompleted, setProjectsCompleted] = useState(user.projects_completed);
  const [city, setCity] = useState(user.address.city);
  const [state, setState] = useState(user.address.state);
  const [country, setCountry] = useState(user.address.country);
  const [postalCode, setPostalCode] = useState(user.address.postal_code);
  const [profession, setProfession] = useState(user.profession);
  const [hourlyRate, setHourlyRate] = useState(user.salary_rate);
  const [selectedAvailableToWork, setSelectedAvailableToWork] = useState(user.is_available_to_work);
  const [selectedAvailability, setSelectedAvailability] = useState(user.availability);
  const [selectedWorkArrangement, setSelectedWorkArrangement] = useState(user.work_setup_preferences);
  const [visible, setVisible] = useState(true);
  const [professionSuggestions, setProfessionSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const suggestionsRef = useRef(null);



  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => {
        setVisible(false);
        setUpdateSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [updateSuccess]);

  const handleClose = () => {
    setVisible(false);
    setUpdateSuccess(false);
  };

  const handleGetProfile = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getProfile(data);
      console.log("Response: ", response)
      if(response.status === 200){
        setProfileInfo(response.data);
        setProfileCoverPhotoPreview(response.data.profile_banner_path);
        setProfilePhotoPreview(response.data.profile_photo_path);
        setAka(response.data.aka);
        setAbout(response.data.about);
        setExperience(response.data.year_of_experience);
        setProjectsCompleted(response.data.projects_completed);
        setCity(response.data.city);
        setState(response.data.state);
        setCountry(response.data.country);
        setPostalCode(response.data.postal_code);
        setProfession(response.data.profession);
        setHourlyRate(response.data.salary_rate);
        setSelectedAvailableToWork(response.data.is_available_to_work);
        setSelectedAvailability(response.data.availability);
        setSelectedWorkArrangement(response.data.work_setup_preferences);
      }
    }
    catch(error){
      console.log("Error getting profile info: ", error);
    }
  }

  const handleCoverPhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileCoverPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setProfileCoverPhoto(file);
    }
  };

  const handleProfilePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setProfilePhoto(file);
    }
  };

  const handleUpdateProfile = async () => {
    dispatch(setIsLoading(true));
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {}
      };
      
      const fields = {
        aka: aka,
        about: about,
        year_of_experience: experience,
        projects_completed: projectsCompleted,
        city: city,
        state: state,
        country: country,
        postal_code: postalCode,
        profession: profession,
        salary_rate: hourlyRate,
        availability: selectedAvailability,
        work_setup_preferences: selectedWorkArrangement
      };
      
      console.log(profileInfo);
      
      Object.keys(fields).forEach((key) => {
        console.log("KEY: ", fields[key], "CHECK: ", profileInfo[key]);
        if (fields[key] !== profileInfo[key]) {
          data.payloads[key] = fields[key];
        }
      });
    
      if (profileCoverPhoto) {
        data.payloads.profile_banner = profileCoverPhoto;
      }
      
      if (profilePhoto) {
        data.payloads.profile_photo = profilePhoto;
      }

      switch(selectedAvailableToWork){
        case "Yes":
          data.payloads.is_available_to_work = true;
          break;
        case "No":
          data.payloads.is_available_to_work = false;
          break;

        default:
          break
      }

      const response = await updateProfile(data);
      console.log("Response: ", response)
      if(response.status === 200){
        setUpdateSuccess(true);
      }
    }
    catch(error){
      console.log("Error updating profile: ", error);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  }


  const debouncedSearch = debounce(async (query) => {
    if (query.length > 1) {
      setIsLoading(true);
      try {
        const response = await searchProfession({ token, search: query });
        if (response.status === 200) {
          setProfessionSuggestions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching profession suggestions:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setProfessionSuggestions([]);
    }
  }, 400); // Adjust delay as needed

  const handleProfessionChange = (e) => {
    const value = e.target.value;
    setProfession(value);
    debouncedSearch(value);
  };

  const handleSuggestionClick = (profession) => {
    setProfession(profession);
    setProfessionSuggestions([]); // Clear suggestions after selection
  };

  const handleClickOutside = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setProfessionSuggestions([]); // Clear suggestions when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);




  return (
    <div className="flex flex-col pt-6 max-md:pt-0 grow text-base pb-8 max-md:mt-8 max-md:max-w-full max-md:px-3 max-[1024px]:w-full">
      <img src="https://devreg.org/backend-server/image-uploads/NPXv%2Bz2aovF0HNvMJ%2Bpl9zdpTGdZdjU2N1RaclMyMFRKR3U0eFE9PQ%3D%3D"  alt=""/>
      {updateSuccess && (
        <div className={`flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm bg-green-100 text-green-600`}>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
          <span className={`font-semibold mx-2 text-green-600`}>
            Account Updated Successfully
          </span>
          <div>
            <FaCheckCircle size={20} />
          </div>
        </div>
      )}

      <div className="flex flex-col mb-6">
        <p className="font-bold text-base mb-2">Edit Profile</p>
        <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Make your profile your own! Update your details to reflect who you are.</p>
      </div>

      {/* profile banner  */}
      <div className="container bg-gray-200 max-w-[1000px] max-h-[200px]  border-neutral-300 border-2 py-7 rounded-lg mt-2 relative  hover:opacity-80">
        <div className="py-4 text-neutral-400">
          <div className="text-center flex flex-col justify-center items-center text-lg">
            <div className="text-base font-semibold leading-5 text-black text-opacity-80">
              Profile Banner
            </div>
            <div className="text-sm15">Add banner</div>
            <div className="text-xs leading-4">
              {`Profile Cover / Ad Space (1000px x 200px)`}
            </div>
            <div className="mt-2  text-sm15 font-bold tracking-tight text-devRegBlue">
              Upload
            </div>
          </div>
        </div>
        {profileCoverPhotoPreview && (
          <img
            src={profileCoverPhotoPreview}
            alt="Profile Banner Preview"
            className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
          />
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleCoverPhotoUpload}
          className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
        />
      </div>

      {/* Profile picture  */}
      <div className="flex gap-4 my-6 text-base leading-4 max-[768px]:flex-col self-center max-[768px]:items-center max-[768px]:text-center relative">
        
        {profilePhotoPreview ? (
          <img
            alt=""
            loading="lazy"
            src={profilePhotoPreview}
            className="shrink-0 max-w-full aspect-square w-[120px] rounded-full border border-gray-400"
          />
        ):(
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbdb59e529344fd004b928608480af2aa91b9d0ae6b51eddb7d4695a84fa07b0?"
            className="shrink-0 max-w-full aspect-square w-[120px] rounded-full"
          />
        )}
        
        <div className="flex flex-col my-auto">
          <div className="font-semibold leading-[121%] text-black text-opacity-80">
            Profile picture
          </div>
          <div className="mt-2 text-sm tracking-tight text-zinc-400">
            PNG, JPG up to 2MB
          </div>
          <div className="mt-2 font-bold tracking-tight text-sky-600">
            Upload
          </div>
        </div>
        <input
          type="file"
          value=""
          accept="image/*"
          onChange={handleProfilePhotoUpload}
          className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
        />
      </div>

      {/* profile details  */}
      <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
        Aka/Alias Name (Hidden)
      </div>
      <input
        value={aka}
        placeholder="Aka/Alias"
        onChange={(e) => setAka(e.target.value)}
        className=" p-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
      />

      <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
        About Me
      </div>
      <textarea
        value={about}
        placeholder="Bio"
        onChange={(e) => setAbout(e.target.value)}
        className="p-3 mt-2 rounded-lg border border-solid border-neutral-300 h-[120px] leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
      />

      <div className="flex flex-wrap gap-4 content-start mt-4">
        <div className="flex flex-col flex-1 justify-center">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Years of Experience
          </div>
          <input
            value={experience}
            placeholder="Years of Experience"
            onChange={(e) => setExperience(e.target.value)}
            className=" px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
          />
        </div>
        <div className="flex flex-col flex-1 justify-center">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            No of Project Completed
          </div>
          <input
            value={projectsCompleted}
            placeholder="No of Project Completed"
            onChange={(e) => setProjectsCompleted(e.target.value)}
            className=" px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
          />
        </div>
      </div>
      <div className="flex gap-2 mt-4 flex-wrap md:flex-nowrap ">
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            City
          </div>
          <input
            value={city}
            placeholder="City"
            onChange={(e) => setCity(e.target.value)}
            className="px-4 py-3 mt-2 rounded-lg border 
        border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            State
          </div>
          <input
            value={state}
            placeholder="State"
            onChange={(e) => setState(e.target.value)}
            className="px-4 py-3 mt-2 
        rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Country
          </div>
          <input
            value={country}
            placeholder="Country"
            onChange={(e) => setCountry(e.target.value)}
            className="px-4 py-3 mt-2 rounded-lg border 
        border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Postal Code
          </div>
          <input
            value={postalCode}
            placeholder="Postal Code"
            onChange={(e) => setPostalCode(e.target.value)}
            className=" px-4 py-3 mt-2 rounded-lg border 
        border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full"
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-4 content-start mt-4">
        <div className="flex flex-col flex-1 justify-center relative">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Profession
          </div>
          <input
            value={profession}
            placeholder="Type to search for professions"
            onChange={handleProfessionChange}

            className=" px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
          />
          {isLoading && (
            <div className="text-gray-500 py-2">Loading...</div>
          )}
          {professionSuggestions.length > 0 && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
              {professionSuggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion.profession)}
                  className="cursor-pointer hover:bg-blue-500 hover:text-white px-4 py-2 transition-colors"
                >
                  {suggestion.profession}
                </li>
              ))}
            </ul>
          )}


        </div>
        <div className="flex flex-col flex-1 justify-center">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Hourly rate
          </div>
          <input
            value={hourlyRate}
            placeholder="Hourly rate"
            onChange={(e) => setHourlyRate(e.target.value)}
            className=" px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
          />
        </div>
      </div>
      <div className="flex gap-4 mt-4 max-[1024px]:flex-wrap">
        <div className="flex flex-col flex-1">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Available to Work
          </div>
          <select
            className={`max-h-[45px] mt-3  flex flex-1  p-3 whitespace-nowrap rounded-lg border border-solid border-neutral-300 ${
              selectedAvailableToWork === "" ? "text-neutral-300" : "text-black"
            }`}
            value={selectedAvailableToWork}
            onChange={(event) => {
              setSelectedAvailableToWork(event.target.value);
            }}
          >
            <option disabled value="">
              Available to Work
            </option>
            <option value="Yes" selected={selectedAvailableToWork === 1}>Yes</option>
            <option value="No" selected={selectedAvailableToWork === 0}>No</option>
          </select>
        </div>
        <div className="flex flex-col flex-1">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Availability
          </div>
          <select
            className={`max-h-[45px] mt-3  flex flex-1  p-3 whitespace-nowrap rounded-lg border border-solid border-neutral-300 ${
              selectedAvailability === "" ? "text-neutral-300" : "text-black"
            }`}
            onChange={(event) => {
              setSelectedAvailability(event.target.value);
            }}
          >
            <option disabled selected={selectedAvailability === null}> Availability</option>

            <option value="Part Time" selected={selectedAvailability === "Part Time"}>Part Time</option>
            <option value="Project Based" selected={selectedAvailability === "Project Based"}>Project Based</option>
            <option value="Sub Contractor" selected={selectedAvailability === "Sub Contractor"}>Sub Contractor</option>
            <option value="Contractor" selected={selectedAvailability === "Contractor"}>Contractor</option>
            <option value="Freelance" selected={selectedAvailability === "Freelance"}>Freelance</option>
          </select>
        </div>
        <div className="flex flex-col flex-1">
          <div className="tracking-tight leading-[110%] text-neutral-600">
            Work Arrangement
          </div>
          <select
            className={`max-h-[45px] mt-3  flex flex-1  p-3 whitespace-nowrap rounded-lg border border-solid border-neutral-300 ${
              selectedWorkArrangement === "" ? "text-neutral-300" : "text-black"
            }`}
            value={selectedWorkArrangement}
            onChange={(event) => {
              setSelectedWorkArrangement(event.target.value);
            }}
          >
            <option disabled value="">
              Work Arrangement
            </option>

            <option value="Work From Home" selected={selectedWorkArrangement === "Work From Home"}>Work From Home</option>
            <option value="Field Work" selected={selectedWorkArrangement === "FField Work"}>Field Work</option>
            <option value="Hybrid" selected={selectedWorkArrangement === "Hybrid"}>Hybrid</option>
            <option value="Office Work" selected={selectedWorkArrangement === "Office Work"}>Office Work</option>
          </select>
        </div>
      </div>
      <button 
        className="justify-center self-end px-6 py-3 mt-10 text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-full max-md:px-5 max-md:w-full"
        onClick={() => handleUpdateProfile()}
      >
        Update
      </button>
    </div>
  );
}

export default Overview;

import React from "react";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash, FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import useLogin from "../../api/customHook/useLogin";
import BacktoHomepage from "./components/BackToHomePage";
import { FaRegEnvelope } from "react-icons/fa";
import { IoKeyOutline } from "react-icons/io5";

function LoginMain() {
  const navigate = useNavigate();
  const {
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    error,
    resendVerificationEmail,
  } = useLogin();

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <div className="w-full min-h-screen bg-[#0082BA] flex justify-center items-center max-md:px-5 max-md:py-14">
      <div className="bg-white rounded-xl w-[570px] max-md:max-w-full p-6">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
            <BacktoHomepage />
          </div>
          <img src="/logoFooter.png" alt="Devreg Logo" className="h-8" />
        </div>
        <hr className="w-full border-t border-gray-200" />
        <div className="text-start max-md:text-center mt-8">
          <h2 className="text-xl font-bold text-gray-700">Welcome to Full ID Check</h2>
          <p className="text-gray-500 mt-2">
            Log in to your account by filling in the details below.
          </p>
        </div>

        {error === "You are not yet verified! Please verify your email." ? (
          <div className="text-red-500 mt-2 text-sm text-center">
            {error}
            <span
              className="border-b border-gray-500 cursor-pointer text-sky-600"
              onClick={resendVerificationEmail}
            >
              {" "}
              resend link
            </span>
          </div>
        ) : (
          <>
            {error === "Verification link sent!" ? (
              <div className="text-green-500 mt-2 text-sm text-center">
                Verification link sent!
              </div>
            ) : (
              <div className="text-red-500 mt-2 text-sm text-center">
                {error}
              </div>
            )}
          </>
        )}

        <form onSubmit={handleSubmit} className="flex flex-col mt-4 space-y-4">
          <div className="relative">
            <FaRegEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              className="w-full pl-10 p-3 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Email"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          <div className="relative">
            <IoKeyOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              className="w-full pl-10 p-3 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Password"
              value={loginPass}
              onChange={(e) => setLoginPass(e.target.value)}
              type={toggleEye ? "text" : "password"}
            />
            <div
              className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
              onClick={handleToggleEye}
            >
              {toggleEye ? (
                <FaEyeSlash size={20} color="#BCBCBC" />
              ) : (
                <FaEye size={20} color="#BCBCBC" />
              )}
            </div>
          </div>

          <div
            className="text-right text-sm text-gray-400 cursor-pointer"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </div>

          <button
            onClick={handleLogin}
            className="w-full py-3 bg-blue-900 text-white rounded-full text-lg font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 hover:bg-blue-800"
          >
            Login
          </button>
        </form>

        {/* spacer */}
        <div className="my-4 flex flex-row justify-center items-center">
          <span className={`flex-1 border-b border-black`}><hr /></span>
          <span className="mx-4">or</span>
          <span className={`flex-1 border-b border-black`}><hr /></span>
        </div>


        <a
          className={`flex items-center justify-center gap-2 rounded-full border border-[#5F5F5F] py-3 text-lg text-[#5F5F5F] font-[500]`}
          href='https://staging-api.fullidcheck.com/auth/google/signup'
        >
          <FcGoogle /> <span>Login with Google</span>
        </a>

        <a
          className={`mt-4 flex items-center justify-center gap-2 rounded-full border border-[#5F5F5F] py-3 text-lg text-[#5F5F5F] font-[500]`}
          href=""
          // href='https://staging-api.fullidcheck.com/auth/google/signin'
        >
          <FaApple /><span className=""> Login with Apple</span>
        </a>

        <div className="text-center mt-4 text-sm text-gray-500">
          Don’t have an account?{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={handleSignUp}
          >
            Sign-up
          </span>
        </div>
      </div>
    </div>
  );
}

export default LoginMain;
